<script>
import Layout from "../layouts/horizontal";
import PageHeader from "@/components/page-header";
import SystemValues from "@/components/system-values";
import { required } from "vuelidate/lib/validators";
import { parseErrors } from '../../helpers'
import Swal from "sweetalert2";
import http from '@/http'


import {
   
} from "@/state/helpers";


export default {
  components: {
    Layout,
    PageHeader,
    SystemValues
  },
  data() {
    
    return {
      indexTypes: [],

      types:[
        { key:'providers', url:'api/providers'},
        { key:'markets', url:'api/markets'},
        { key:'indexTypes', url:'api/index-types'},
        { key:'indexSubtypes', url:'api/index-sub-types'},
        { key:'currencies', url:'api/currencies'},
        { key:'exchange', url:'api/exchanges'}
      ],
      keysLoading: false,
      selectedType: null,
      title: this.$t("pages.dictionaries.title"),
      showmodal: false,
      editedItemId: null,
      submitted: false,
      editedItem: {
        parent: null,
        name:null,
        displayName:null,
        description: null
      },
      items:[],
      errors:[],
      isBusy: false,
      keyword: null,
      selectedKey: null,
      saving: null,
      keys:[],
      selectedKeyValue: null,
      name: null,
      currentPage: 1,
      perPage: 20,
      totalItems: 0,
      breadcrumbs: [
        {
          text: this.$t("pages.dictionaries.title"),
          active: true
        }
      ]
    };
  },
  validations: {
    editedItem: {
      displayName: { required },
      name: { required }
    }
  },
  created() {
    this.selectedType = this.types[0];
    this.loadIndexTypes();
    this.loadKeys();
    this.load();
  },
  watch: {
    currentPage: {
      handler: function () {
        this.load();
      }
    },
    selectedType: {
      handler: function () {
        this.loadKeys();
      }
    }
  },
  computed: {
    totalPages() {
      return Math.ceil(this.totalItems / this.perPage);
    }
  },
  methods: {
    async applyFilters(){
      this.currentPage = 1;
      this.load();
    },
    async loadKeys(){
      this.selectedKeyValue = null;
      this.selectedKey = null;
      this.keysLoading = true;
      const resp = await http.get(`${this.selectedType.url}/system-values/keys`);
      this.keys = resp.data;
      this.keysLoading = false;
    },
    async loadIndexTypes() {
      const resp = await http.get(`api/index-types`);
      this.indexTypes = resp.data;
    },
    async load()  {
      this.isBusy = true;
    
      let sv = {};
      if(this.selectedKey){
        sv[this.selectedKey] = this.selectedKeyValue;
      }
      const resp = await http.post(`${this.selectedType.url}/search`, {
        take: this.perPage,
        skip: this.perPage * (this.currentPage - 1),
        keyword:  this.keyword,
        name: this.name,
        systemValues:sv
      });
      this.items = resp.data.items;
      this.totalItems = resp.data.total;
      this.isBusy = false;  
    },
    selectType(t) {
      this.selectedType = t;
      this.load();
    },
    hideModal() {
      this.processing = false;
      this.showmodal = false;
      this.submitted = false;
      this.editedItem = {};
      this.errors = [];
    },
    showModal(item) {
      this.processing = false;
      this.showmodal = true;
      this.submitted = false;
      this.errors = [];
      this.editedItem = item ? {...item, parent: (item.parent || {}).id || null} : 
      {
        parent: null
      };
      this.editedItemId = item ? item.id : null;
    },
    handleSubmit() {
      this.submitted = true;
      this.errors = [];
      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        this.saving = true;
        const dto = {...this.editedItem, id: this.editedItemId };
        const req = !this.editedItemId ? http.post(this.selectedType.url, dto) : http.put(`${this.selectedType.url}`, dto);
        req.then(() => {
            this.saving = false;
          this.hideModal();
          this.load();
        })
        .catch(error => {
          this.saving = false;
          this.errors = parseErrors(error);
        });
      }
    },
    confirmDelete(item) {
      Swal.fire({
        title: this.$t("common.areYouSure"),
        text: this.$t("common.youCannotRevertOp"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: this.$t("common.yesDelete")
      }).then(result => {
        if (result.value) {
         this.handleDelete(item);
        }
      });
    },
    handleDelete(item) {
     http.delete(`${this.selectedType.url}/${item.id}`).then(() => {
        this.hideModal();
          Swal.fire(this.$t("common.deleted"), this.$t("common.recWasDeleted"), "success");
          this.load();
      })
      .catch(error => {
        this.errors= parseErrors(error);
      });
    },
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="breadcrumbs" />
    <div class="row">
      <!-- Right Sidebar -->
      <div class="col-12">
        <div class="email-leftbar card cardc8 p-0">
        <div class="card-content m-3">
          <b-form-group
            class="mt-2"
            :label="$t('pages.dictionaries.type')"
            label-for="type"
          >
            <select class="form-control" v-model="selectedType">
              <option v-for="t in types" :value="t" :key="t.key">
                {{$t('pages.dictionaries.'+t.key)}}
              </option>
            </select>
          </b-form-group>

          <b-form-group
            class="mt-2"
            :label="$t('pages.dictionaries.keyword')"
            label-for="type"
          >
            <div class="search-box">
              <div class="position-relative">
                <input type="text" class="form-control rounded" v-model="keyword" :placeholder="$t('pages.dictionaries.keyword')" id="keywords"/>
                <i class="mdi mdi-magnify search-icon"></i>
              </div>
            </div>
          </b-form-group>

           <b-form-group
            class="mt-2"
            :label="$t('pages.dictionaries.name')"
            label-for="type"
          >
            <div class="search-box">
              <div class="position-relative">
                <input type="text" class="form-control rounded" v-model="name" :placeholder="$t('pages.dictionaries.name')"  id="name"/>
                <i class="mdi mdi-magnify search-icon"></i>
              </div>
            </div>
          </b-form-group>

          <b-form-group
            class="mt-2"
            label-for="type"
          >
            <template v-slot:label>
              {{$t('pages.dictionaries.key')}}&nbsp;<i v-if="keysLoading" class="fa fa-spin fa-spinner"></i>
            </template>
            <select class="form-control" v-model="selectedKey">
              <option :value="null">--- Select key ---</option>
              <option v-for="t in keys" :value="t" :key="t">
                {{t}}
              </option>
            </select>
            
          </b-form-group>
          
          <b-form-group
            v-if="selectedKey"
            class="mt-2"
            :label="$t('pages.dictionaries.value')"
            label-for="value"
          >
          
            <div class="search-box">
              <div class="position-relative">
                <input type="text" class="form-control rounded" v-model="selectedKeyValue" :placeholder="$t('pages.dictionaries.value')" id="value"/>
                <i class="mdi mdi-magnify search-icon"></i>
              </div>
            </div>
          </b-form-group>
          <hr/>
          <b-form-group>
            <button class="btn btn-block btn-secondary" @click="applyFilters">{{$t("common.applyFilters")}}</button>
          </b-form-group>
        </div>
        </div>
        <div class="email-rightbar mb-2">
          <div class="card p-0 cardc8">
          <div class="card-content m-3">
            <div class="form-group ">
             <b-button variant="secondary" class="mb-2" @click="showModal()">{{$t("common.newRecord")}}</b-button>
            </div>

            <div class="table-responsive" style="position: relative; min-height:300px">
              <table
                class="table table-centered nowrap"
                style="border-collapse: collapse; border-spacing: 0; width: 100%;"
              >
                <thead >
                  <tr>
                    <th style="width: 30px;max-width:30px;"></th>
                    <th v-if="selectedType.key === 'indexSubtypes'">Index Type</th>
                    <th>{{$t("tables.dictionary.columns.name")}}</th>
                    <th v-if="selectedKey">{{selectedKey}}</th>
                    <th>{{$t("tables.dictionary.columns.description")}}</th>
                    <th style="width: 30px;max-width:30px;"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="items.length === 0 && isBusy !== true">
                    <td colspan="100">
                      <span class="text-muted">{{$t("common.noRecordsFound")}}</span>
                    </td>
                  </tr>
                  <tr v-for="(item, index) in items" :key="index">
                   <td>
                      <a
                        v-if="!item.isSystem"
                        href="javascript:void(0);"
                        class="mr-3 text-primary"
                        v-b-tooltip.hover
                        @click="showModal(item)"
                        
                      >
                        <i class="mdi mdi-pencil font-size-18"></i>
                      </a>
                    </td> 
                    <td v-if="selectedType.key === 'indexSubtypes'">
                      {{(item.parent || {}).displayName}}
                    </td>
                     <td>
                      <span class="mr-1">{{item.name}}</span>
                      <em class="text-info small" v-if="item.name !== item.displayName">[{{item.displayName}}]</em>
                    </td>
                    <td v-if="selectedKey">
                      {{item.systemValues[selectedKey]}}
                      <em class="text-muted small" v-if="!item.systemValues[selectedKey]">{{$t("common.noValue")}}</em>
                    </td>
                    <td>{{item.description}}</td>
                      <td>
                      <a
                        v-if="!item.isSystem"
                        href="javascript:void(0);"
                        class="mr-3 text-danger"
                        :disabled="saving"
                        @click="confirmDelete(item)"
                        title="Delete"
                      >
                        <i class="fa fa-times font-size-18"></i>
                      </a>
                    </td> 
                  </tr>
                </tbody>
              </table>
              <div style="position:absolute; left:0; right:0; top:0; bottom:0; background-color: rgba(255,255,255,0.5); text-align: center; padding-top:10%;"
                v-if="isBusy">
                <span>
                  <i class="fa fa-spin fa-spinner" style="font-size:3rem"></i>
                </span>
              </div>
            </div>
          </div>
          </div>
           <div class="row justify-content-md-between align-items-md-center">
            <div class="col-xl-7">{{$t('common.totalRecords')}} {{totalItems}}</div>
            <!-- end col-->
            <div class="col-xl-5">
              <div class="text-md-right float-xl-right mt-2 pagination-rounded">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalItems"
                  :per-page="perPage"
                  @input="load"
                ></b-pagination>
              </div>
            </div>
            <!-- end col-->
          </div>
        </div>
      </div>
    </div>

   <!-- Modal -->
    <b-modal
  
      v-model="showmodal"
      :title="editedItemId ? $t('common.editRecord') :  $t('common.newRecord')"
      title-class="text-dark font-18"
      size="lg"
      hide-footer
    >
      <form @submit.prevent="handleSubmit" autocomplete="off">
         <b-alert
          variant="danger"
          class="mt-3"
          v-if="errors.length > 0"
          show
          dismissible
          >
          <ul>
            <li v-for="e in errors" :key="e">{{e}}</li>
          </ul>
        </b-alert>
        
         <div class="form-group" v-if="selectedType.key === 'indexSubtypes'">
          <label for="name">Index Type [*]</label>
          <select class="form-control" v-model="editedItem.parent">
            <option :value="null">--- Not set ---</option>
            <option v-for="it in indexTypes" :value="it.id" :key="it.id">{{it.displayName}}</option>
          </select>
        </div>

         <div class="form-group">
          <label for="name">{{$t('forms.dictionary.name')}} [*]</label>
          <input
            id="name"
            v-model="editedItem.name"
            type="text"
            class="form-control"
            placeholder=""
            :class="{ 'is-invalid': submitted && $v.editedItem.name.$error }"
          />
          <div
            v-if="submitted && !$v.editedItem.name.required"
            class="invalid-feedback"
          >{{$t('forms.dictionary.name-required')}}</div>
        </div>
         <div class="form-group">
          <label for="displayName">{{$t('forms.dictionary.displayName')}} [*]</label>
          <input
            id="displayName"
            v-model="editedItem.displayName"
            type="text"
            class="form-control"
            placeholder=""
            :class="{ 'is-invalid': submitted && $v.editedItem.displayName.$error }"
          />
          <div
            v-if="submitted && !$v.editedItem.displayName.required"
            class="invalid-feedback"
          >{{$t('forms.dictionary.displayName-required')}}</div>
        </div>
         <div class="form-group">
          <label for="description">{{$t('forms.dictionary.description')}}</label>
          <textarea
            id="description"
            v-model="editedItem.description"
            class="form-control"
           />
        
        </div>
        <h6>System Values</h6>
        <system-values :values="editedItem.systemValues" @change="o => editedItem.systemValues = o"></system-values>
        <div>
          <b-button class="ml-1 float-left" @click="hideModal">{{$t('forms.dictionary.closeButton-title')}}</b-button>
          <button type="submit" class="btn btn-success float-right" :disabled="saving">
            <i class="fa fa-spin fa-spinner" v-if="saving"></i>
            {{$t('forms.dictionary.saveButton-title')}}
          </button>
        </div>
      </form>
    </b-modal>
    <!-- end modal -->
  </Layout>
</template>